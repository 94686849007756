let S3_URL = process.env.REACT_APP_DYASHIN_S3_URL;
let S3_APPLICATION_URL = process.env.REACT_APP_DSEDIFY_UI_URL;

const Linkedin = S3_URL + "/v3/assests/icons/linkedInFooter.png";
const Facebook = S3_URL + "/v3/assests/icons/facebookFooter.png";
const Youtube = S3_URL + "/v3/assests/icons/youtubeFooter.png";
const Instagram = S3_URL + "/v3/assests/icons/instagramFooter.png";
const Pinterest = S3_URL + "/v3/assests/icons/pinterestFooter.png";
const Quora = S3_URL + "/v3/assests/icons/quoraFooter.png";
const Xicon = S3_URL + "/v3/assests/icons/XlogoFooter.png";
const DsedifyApplicationURL = S3_APPLICATION_URL + "/moderator/login";

export const FOOTERITEMS = {
  "Campus Programs": [
    { title: "Campus Internship Program", link: "/campus-programs/cip" },
    {
      title: "Campus Projects Program",
      link: "/campus-programs/cpp",
    },
    { title: "Tech Connect Program", link: "/campus-programs/tcp" },
    {
      title: "Student Success Program",
      link: "/campus-programs/ssp",
    },
    {
      title: "Faculty Development Program",
      link: "/campus-programs/fdp",
    },
    {
      title: " Faculty Mirroring Program",
      link: "/campus-programs/fmp",
    },
  ],
  "Off-Campus Programs": [
    {
      title: "Experiential Learning Internship program",
      link: "/off-campus-programs/elip",
    },
    {
      title: " Professional Development program",
      link: "/off-campus-programs/pdp",
    },
  ],
  "Corporate Programs": [
    { title: "Executive Learning Program", link: "/corporate-programs/elp" },
    { title: "Immersive Learning Program", link: "/corporate-programs/ilp" },
    { title: "Virtual Learning Program", link: "/corporate-programs/vlp" },
  ],

  Technology: [
    {
      title: "Software Development",
      link: "/technology/software-development",
    },
    { title: "Software QA & Testing", link: "/technology/software-testing" },
    {
      title: "Engineering Services",
      link: "/technology/engineering-services",
    },
    { title: "Embedded Systems", link: "/technology/embedded-system" },
  ],
  "Know More": [
    { title: "About Dyashin", link: "https://dyashin.com/our-company" },
    { title: "Privacy policy", link: "/privacy-policy" },
    { title: "Refund Policy", link: "/refund-policy" },
    { title: "Terms & Conditions", link: "/terms-and-conditions" },
  ],
  "For Employers": [
    {
      title: "Moderator Login",
      link: DsedifyApplicationURL,
    },
  ],
  Help: [
    {
      title: "Faqs",
      link: "/faq",
    },
  ],
};

export const SOCIALMEDIAICONS = [
  {
    name: "Linkedin",
    icon: Linkedin,
    link: "https://in.linkedin.com/company/dyashin-technosoft",
  },
  {
    name: "facebook",
    icon: Facebook,
    link: "https://www.facebook.com/DyashinTechnosoft/",
  },
  {
    name: "Youtube",
    icon: Youtube,
    link: "https://www.youtube.com/channel/UCZ5SpfZakaRHB0z13AlHEKA",
  },
  {
    name: "instagram",
    icon: Instagram,
    link: "https://www.instagram.com/dyashin_technosoft/",
  },
  {
    name: "Pinterest",
    icon: Pinterest,
    link: "https://in.pinterest.com/dyashintechnosoft/",
  },
  { name: "xicon", icon: Xicon, link: "https://x.com/DyashinTechno" },
  {
    name: "Quora",
    icon: Quora,
    link: "https://www.quora.com/profile/Dyashin-Technosoft",
  },
];
